<template>
    <v-container>
        <div class="mt-10">
            <h3 class="text-center">{{ $t("message.title-cek-info-regis") }}</h3>
            <v-divider style="background-color: grey; margin-bottom: 10px"></v-divider>
            <p class="text-center">
                {{ $t("message.desc-register") }}
            </p>
            <v-spacer></v-spacer>

            <v-container>
                <div id="login-confirmation">
                    <div class="mb-4 mt-4">
                        <p class="ps-3 title">{{ $t("message.label-login-info") }}</p>
                    </div>
                    <div>
                        <v-row>
                            <v-col>
                                <v-card
                                cols="4"
                                class="pe-1 ps-5"
                                style="font-size: 14"
                                flat
                                tile
                                >
                                {{ $t("message.label-email") }}
                                </v-card>
                            </v-col>
                            <v-col cols="8">
                                <v-card
                                class="text-left pe-1 me-3 ps-5"
                                style="font-size: 14px"
                                flat
                                tile
                                >
                                {{ email }}
                                </v-card>
                            </v-col>
                        </v-row>
                        <hr class="mx-4 black--text" />
                        <v-row>
                            <v-col>
                                <v-card
                                cols="4"
                                class="text-left pe-1 ps-5"
                                style="font-size: 14px"
                                flat
                                tile
                                >
                                {{ $t("message.title-memberId") }}
                                </v-card>
                            </v-col>
                            <v-col cols="8">
                                <v-card
                                class="text-left pe-1 me-3 ps-5"
                                style="font-size: 14px"
                                flat
                                tile
                                >
                                {{ memberId }}
                                </v-card>
                            </v-col>
                        </v-row>
                        <hr class="mx-4 black--text" />
                        <v-row>
                            <v-col cols="">
                                <v-card
                                cols="4"
                                class="text-left pe-1 ps-5"
                                style="font-size: 14px"
                                flat
                                tile
                                >
                                {{ $t("message.label-password") }}
                                </v-card>
                            </v-col>
                            <v-col cols="8">
                                <v-card
                                class="text-left pe-1 me-3 ps-5"
                                style="font-size: 14px"
                                flat
                                tile
                                >
                                <input style="padding: 5px 0" type="password" class="text-start" disabled :value="password">
                                </v-card>
                            </v-col>
                        </v-row>
                        <hr class="mx-4 black--text" />
                    </div>
                    <div id="member-info" class="mt-5">
                        <div class="mb-4">
                            <p class="ps-3 title">{{ $t("message.label-member-info") }}</p>
                        </div>
                        <div>
                            <v-row>
                                <v-col>
                                    <v-card
                                    cols="4"
                                    class="pe-1 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ $t("message.title-name") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ name }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.title-furigana") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ furigana }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1  ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.gender") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ sex }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />

                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-bod") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ birthday }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-postal") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    〒{{ formatPostalcode(postalcode) }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col cols="4">
                                    <v-card class="pe-1 ps-5" style="font-size: 14px;" flat tile>
                                    {{ $t("message.label-address") }} ({{
                                        $t("message.label-prefecture")
                                    }})
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ prefecture }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-address") }} ({{
                                        $t("message.label-city")
                                    }})
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ city }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-address") }} ({{
                                        $t("message.label-district")
                                    }})
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ district }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-address-ward") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >


                                    {{ ward }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-address-street_address") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >

                                    {{ street_address }}

                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.label-building") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    <div v-if="!building">
                                        {{ "" }}
                                    </div>
                                    <div v-else>
                                        {{ building }}
                                    </div>

                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                            <v-row>
                                <v-col>
                                    <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                                    {{ $t("message.phone-number") }}
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-card
                                    class="text-left pe-1 me-3 ps-5"
                                    style="font-size: 14px"
                                    flat
                                    tile
                                    >
                                    {{ phoneNumber }}
                                    </v-card>
                                </v-col>
                            </v-row>
                            <hr class="mx-4 black--text" />
                        </div>
                    </div>
                </div>
            </v-container>
            <div class="d-flex flex-row justify-start align-center mt-4">
                <v-icon @click="$router.go(-1)" size="30" color="black">
                    mdi-chevron-left
                </v-icon>
                <p @click="$router.go(-1)">{{ $t("message.back-input-regis") }}</p>
            </div>
            <div class="d-flex flex-row justify-center align-center me-0 pt-6 pb-6" tile>
                <div class="m-2">
                    <v-btn color="#E93B81" type="button" @click.prevent="submitRegister" height="45" width="250">
                        <span style="color: white">{{ $t("message.btn-register-now") }}</span>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "CheckRegistration",
    data() {
        return {
            showPassword: false,
            showConPassword: false,
            email: "",
            memberId: "",
            name: "",
            password: "",
            confirmPassword: "",
            postalcode: "",
            building: "",
            city: "",
            district: "",
            ward: "",
            street_address: "",
            furigana: "",
            prefecture: "",
            phoneNumber: "",
            sex: "",
            birthday: "",
            token: "",
            message: ""
        }
    },
    methods: {
        formatPostalcode(postalcode) {
            if(postalcode != null) {
                let newPostcode = postalcode.slice(0, 3)+'-'+postalcode.slice(3)
                return newPostcode
            } else {
                return
            }
        },
        userData() {
            let result = JSON.parse(localStorage.user_register)

            if(result.gender == 1) {
                this.sex = "男性"
            } else {
                this.sex = "女性"
            }



            this.email = result.email
            this.name = result.customer_name
            this.password = result.password
            this.confirmPassword = result.password_confirmation
            this.phoneNumber = result.phone0+result.phone1+result.phone2
            this.building = result.building
            this.memberId = result.memberId
            this.postalcode = result.postalcode
            this.city = result.city
            this.ward = result.ward
            this.district = result.district
            this.prefecture = result.prefecture
            this.street_address = result.street_address
            this.furigana = result.furigana
            this.birthday = result.bod
            this.token = result.token
            this.message = result.message
        },
        submitRegister() {
            let tempGender = JSON.parse(localStorage.user_register)

            let payload = {
                email: this.email,
                customer_name: this.name,
                password: this.password,
                password_confirmation: this.confirmPassword,
                member_id: this.memberId,
                furigana: this.furigana,
                prefecture: this.prefecture,
                city: this.city,
                ward: this.ward,
                district: this.district,
                postal_code: this.postalcode,
                street_address: this.street_address,
                building: this.building,
                phone: this.phoneNumber,
                gender: tempGender.gender,
                bod: this.birthday,
                token: this.token,
                message: this.message
            }
            this.$store.dispatch("auth_module/register", payload)
        }
    },
    computed: {

    },
    created() {
        this.userData()
    }
}
</script>

<style>
.title {
    /* text-transform: capitalize; */
    border-left: 10px solid #ff0090;
    font-size: 16px;
    color: #424242;
}
table, td {
  border: 1px solid black;
  border-left: none;
  border-top: none;
  border-right: none;
  padding: 10px;
  /* border-right: none; */
}
table, .right-style {
  height: 40px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
</style>